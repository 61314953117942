import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const ProjectSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  ownerId: z.string(),
  users: z.array(
    z.object({
      userId: z.string().nullable(),
      email: z.string().nullable(),
      role: z.string().nullable(),
      status: z.string().nullable(),
    })
  ),
  createdAt: z.union([z.date(), z.string()]),
  createdBy: z.string().nullable(),
  numberOfStudies: z.number().nullable(),
  teamMembersSize: z.number().nullable(),
  workspaceId: z.string().nullable(),
});

export const PaginatedProjectsSchema = createPaginatedResultsSchema(ProjectSchema);

export const ProjectMemberSchema = z.object({
  id: z.union([z.string(), z.null()]),
  email: z.string(),
  role: z.string(),
  status: z.string(),
  projectInviteId: z.union([z.string(), z.null()]),
});
