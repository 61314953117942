// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import {
  InterviewAnnotation,
  InterviewConversation,
  PaginatedUserInterviews,
  UserInterview,
} from "./types";
// Schemas
import {
  InterviewAnnotationSchema,
  InterviewConversationSchema,
  PaginatedUserInterviewsSchema,
  UserInterviewSchema,
} from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Zod
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const userInterviewsApi = createApi({
  reducerPath: "userInterviewsApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    /***** --- Get User Interview After Streaming Query --- *****/
    getUserInterview: builder.query<UserInterview, { interviewId: string }>({
      query: ({ interviewId }) => ({
        type: "sdk",
        method: sdkApis.interviews.getInterview(interviewId),
      }),
      extraOptions: {
        dataSchema: UserInterviewSchema,
      },
    }),
    /***** --- Get User Interview After Streaming Query --- *****/
    getUserInterviews: builder.query<
      PaginatedUserInterviews,
      { interviewIds?: string[]; studyId?: string }
    >({
      query: ({ interviewIds, studyId }) => ({
        type: "sdk",
        method: sdkApis.interviews.listInterviews(
          interviewIds?.join(",") || "",
          undefined,
          undefined,
          studyId
        ),
      }),
      extraOptions: {
        dataSchema: PaginatedUserInterviewsSchema,
      },
    }),
    /***** --- Regenerate User Interview Mutation --- *****/
    regenerateUserInterview: builder.mutation<UserInterview, { interviewId: string }>({
      query: ({ interviewId }) => ({
        type: "sdk",
        method: sdkApis.interviews.regenerateInterview(interviewId),
      }),
      extraOptions: {
        dataSchema: UserInterviewSchema,
      },
    }),
    askUserInterviewFollowUp: builder.mutation<
      InterviewConversation,
      { interviewId: string; message: string }
    >({
      query: ({ interviewId, message }) => ({
        type: "sdk",
        method: sdkApis.interviews.interviewFollowUp(interviewId, { message }),
      }),
      extraOptions: {
        dataSchema: InterviewConversationSchema,
      },
    }),
    /***** --- Give User Interview Feedback Mutation --- *****/
    giveUserInterviewFeedback: builder.mutation<void, { interviewId: string; helpful: boolean }>({
      query: ({ interviewId, helpful }) => ({
        type: "fetch",
        url: `/userInterviews/feedback/${interviewId}`,
        method: "POST",
        body: { helpful },
      }),
    }),
    /***** --- Add User Interview Annotation Mutation --- *****/
    addUserInterviewAnnotation: builder.mutation<
      InterviewAnnotation,
      {
        conversationId: string;
        userInterviewId: string;
        annotation: string;
        startIndex: number;
        endIndex: number;
        label: string;
      }
    >({
      query: ({ conversationId, userInterviewId, annotation, startIndex, endIndex, label }) => ({
        type: "sdk",
        method: sdkApis.annotations.createAnnotation({
          conversationId,
          userInterviewId,
          annotation,
          startIndex,
          endIndex,
          label,
        }),
      }),
      extraOptions: {
        dataSchema: InterviewAnnotationSchema,
      },
    }),
    /***** --- Update User Interview Annotation Label Mutation --- *****/
    updateUserInterviewAnnotationLabel: builder.mutation<
      InterviewAnnotation,
      { annotationId: string; label: string }
    >({
      query: ({ annotationId, label }) => ({
        type: "sdk",
        method: sdkApis.annotations.updateAnnotation(annotationId, {
          label,
        }),
      }),
      extraOptions: {
        dataSchema: InterviewAnnotationSchema,
      },
    }),
    /***** --- Delete User Interview Annotation Mutation --- *****/
    deleteUserInterviewAnnotation: builder.mutation<
      void,
      { annotationId: string; userInterviewId: string; conversationId: string }
    >({
      query: ({ annotationId }) => ({
        type: "sdk",
        method: sdkApis.annotations.deleteAnnotation(annotationId),
      }),
    }),
  }),
});

// Create the regular slice
const userInterviewsSlice = createSlice({
  name: "userInterviews",
  initialState,
  reducers: {
    /***** --- Reset User Interviews --- *****/
    resetUserInterviews: () => initialState,
    updateUserInterviewsSse: (
      state,
      action: PayloadAction<{ userInterview: UserInterview; timestamp: Date }>
    ) => {
      const { userInterview, timestamp } = action.payload;
      const interviewExists = state.data?.find((interview) => interview.id === userInterview.id);

      state.lastSseEventTimestamp = timestamp;
      state.data = interviewExists
        ? state.data?.map((interview) =>
            interview.id === userInterview.id ? userInterview : interview
          )
        : [...(state.data || []), userInterview];
    },
    addUserInterviewConversations: (
      state,
      action: PayloadAction<{ conversation: InterviewConversation; timestamp: Date }>
    ) => {
      const { conversation, timestamp } = action.payload;
      const conversationExists = state.data
        ?.find((userInterview) => userInterview.id === conversation.interviewId)
        ?.conversations?.find((c) => c.id === conversation.id);

      state.lastSseEventTimestamp = timestamp;
      state.data = state.data?.map((userInterview) =>
        conversation.interviewId === userInterview.id
          ? {
              ...userInterview,
              conversations: conversationExists
                ? userInterview.conversations?.map((currentConversation) =>
                    currentConversation.id === conversation.id ? conversation : currentConversation
                  )
                : [...(userInterview.conversations || []), conversation],
            }
          : userInterview
      );
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.getUserInterview.matchPending,
          userInterviewsApi.endpoints.getUserInterviews.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.getUserInterview.matchFulfilled,
          userInterviewsApi.endpoints.getUserInterview.matchRejected,
          userInterviewsApi.endpoints.getUserInterviews.matchFulfilled,
          userInterviewsApi.endpoints.getUserInterviews.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get User Interview Fulfilled --- *****/
      .addMatcher(userInterviewsApi.endpoints.getUserInterview.matchFulfilled, (state, action) => {
        const { interviewId } = action.meta.arg.originalArgs;
        const interviewExists = state.data?.find(
          (userInterview) => userInterview.id === interviewId
        );

        if (interviewExists) {
          state.data = state.data?.map((userInterview) =>
            userInterview.id === interviewId
              ? { ...userInterview, ...action.payload }
              : userInterview
          );
        } else {
          state.data = [...(state.data || []), { ...action.payload }];
        }
      })
      /***** --- Handle Regenerate User Interview Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.regenerateUserInterview.matchFulfilled,
        (state, action) => {
          const { interviewId } = action.meta.arg.originalArgs;
          state.data = state.data?.map((userInterview) =>
            userInterview.id === interviewId ? action.payload : userInterview
          );
        }
      )
      /***** --- Handle Get User Interviews  Fulfilled --- *****/
      .addMatcher(userInterviewsApi.endpoints.getUserInterviews.matchFulfilled, (state, action) => {
        state.data = action.payload.items;
      })
      /***** --- Handle Give User Interview Feedback Fulfilled --- *****/
      // .addMatcher(
      //   userInterviewsApi.endpoints.giveUserInterviewFeedback.matchFulfilled,
      //   (state, action) => {
      //     const { interviewId, helpful } = action.meta.arg.originalArgs;
      //     const prevInterview = state.data?.[interviewId];

      //     if (prevInterview) {
      //       state.data = {
      //         ...state.data,
      //         [interviewId]: {
      //           ...prevInterview,
      //           data: {
      //             ...prevInterview.data,
      //             helpful,
      //           },
      //         },
      //       };
      //     }
      //   }
      // )
      /***** --- Handle Add User Interview Annotation Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.addUserInterviewAnnotation.matchFulfilled,
        (state, action) => {
          const { userInterviewId, conversationId } = action.payload;
          const prevInterview = state.data?.find(
            (userInterview) => userInterview.id === userInterviewId
          );

          if (prevInterview) {
            state.data = state.data?.map((userInterview) =>
              userInterview.id === userInterviewId
                ? {
                    ...userInterview,
                    conversations: userInterview.conversations?.map((conversation) =>
                      conversation.id === conversationId
                        ? {
                            ...conversation,
                            annotations: [...(conversation.annotations || []), action.payload],
                          }
                        : conversation
                    ),
                  }
                : userInterview
            );
          }
        }
      )
      /***** --- Handle Update User Interview Annotation Label Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.updateUserInterviewAnnotationLabel.matchFulfilled,
        (state, action) => {
          const { userInterviewId, conversationId, id } = action.payload;
          const prevInterview = state.data?.find(
            (userInterview) => userInterview.id === userInterviewId
          );

          if (prevInterview) {
            state.data = state.data?.map((userInterview) =>
              userInterview.id === userInterviewId
                ? {
                    ...userInterview,
                    conversations: userInterview.conversations?.map((conversation) =>
                      conversation.id === conversationId
                        ? {
                            ...conversation,
                            annotations: conversation.annotations?.map((annotation) =>
                              annotation.id === id ? action.payload : annotation
                            ),
                          }
                        : conversation
                    ),
                  }
                : userInterview
            );
          }
        }
      )
      /***** --- Handle Delete User Interview Annotation Fulfilled --- *****/
      .addMatcher(
        userInterviewsApi.endpoints.deleteUserInterviewAnnotation.matchFulfilled,
        (state, action) => {
          const { userInterviewId, annotationId, conversationId } = action.meta.arg.originalArgs;
          const prevInterview = state.data?.find(
            (userInterview) => userInterview.id === userInterviewId
          );

          if (prevInterview) {
            state.data = state.data?.map((userInterview) =>
              userInterview.id === userInterviewId
                ? {
                    ...userInterview,
                    conversations: userInterview.conversations?.map((conversation) =>
                      conversation.id === conversationId
                        ? {
                            ...conversation,
                            annotations: conversation.annotations?.filter(
                              (annotation) => annotation.id !== annotationId
                            ),
                          }
                        : conversation
                    ),
                  }
                : userInterview
            );
          }
        }
      )
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          userInterviewsApi.endpoints.getUserInterview.matchRejected,
          userInterviewsApi.endpoints.getUserInterviews.matchRejected,
          userInterviewsApi.endpoints.giveUserInterviewFeedback.matchRejected,
          userInterviewsApi.endpoints.addUserInterviewAnnotation.matchRejected,
          userInterviewsApi.endpoints.updateUserInterviewAnnotationLabel.matchRejected,
          userInterviewsApi.endpoints.deleteUserInterviewAnnotation.matchRejected,
          userInterviewsApi.endpoints.regenerateUserInterview.matchRejected,
          userInterviewsApi.endpoints.askUserInterviewFollowUp.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetUserInterviews, addUserInterviewConversations, updateUserInterviewsSse } =
  userInterviewsSlice.actions;

// Export hooks
export const {
  useGiveUserInterviewFeedbackMutation,
  useAddUserInterviewAnnotationMutation,
  useUpdateUserInterviewAnnotationLabelMutation,
  useDeleteUserInterviewAnnotationMutation,
  useGetUserInterviewQuery,
  useLazyGetUserInterviewQuery,
  useLazyGetUserInterviewsQuery,
  useRegenerateUserInterviewMutation,
  useAskUserInterviewFollowUpMutation,
} = userInterviewsApi;

// Combine the reducers
export const userInterviewsReducer = {
  [userInterviewsApi.reducerPath]: userInterviewsApi.reducer,
  userInterviews: userInterviewsSlice.reducer,
};
