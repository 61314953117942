// Redux Toolkit
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { ResearchAssistantStatus, ResearchAssistant, ResearchAssistantStudyType } from "./types";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const researchAssistantApi = createApi({
  reducerPath: "researchAssistantApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    /***** --- Init Assistant Mutation --- *****/
    initAssistant: builder.mutation<ResearchAssistant, { projectId: string }>({
      query: ({ projectId }) => ({
        type: "sdk",
        method: sdkApis.researchAssistant.createAssistant({ projectId }),
      }),
    }),
    /***** --- Send Assistant Message Mutation --- *****/
    sendAssistantMessage: builder.mutation<
      ResearchAssistant,
      {
        assistantId: string;
        message: string;
        studyType: ResearchAssistantStudyType;
        isLiteral?: boolean;
      }
    >({
      query: ({ assistantId, message, studyType, isLiteral }) => ({
        type: "sdk",
        method: sdkApis.researchAssistant.chat(assistantId, {
          message,
          studyType,
          lock: isLiteral,
        }),
      }),
    }),
  }),
});

// Create the regular slice
const researchAssistantSlice = createSlice({
  name: "researchAssistant",
  initialState,
  reducers: {
    /***** --- Reset Research Assistant --- *****/
    resetResearchAssistant: () => initialState,
    /***** --- Mark Research Assistant As Completed --- *****/
    markResearchAssistantAsCompleted: (state) => {
      state.data = {
        ...state.data,
        id: state.data?.id || "",
        status: ResearchAssistantStatus.COMPLETED,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(researchAssistantApi.endpoints.sendAssistantMessage.matchPending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(
          researchAssistantApi.endpoints.sendAssistantMessage.matchFulfilled,
          researchAssistantApi.endpoints.sendAssistantMessage.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Init Assistant Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          researchAssistantApi.endpoints.initAssistant.matchFulfilled,
          researchAssistantApi.endpoints.sendAssistantMessage.matchFulfilled
        ),
        (state, action) => {
          state.data = action.payload;
        }
      )
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          researchAssistantApi.endpoints.initAssistant.matchRejected,
          researchAssistantApi.endpoints.sendAssistantMessage.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export hooks
export const { useInitAssistantMutation, useSendAssistantMessageMutation } = researchAssistantApi;

// Export actions
export const { resetResearchAssistant, markResearchAssistantAsCompleted } =
  researchAssistantSlice.actions;

// Combine the reducers
export const researchAssistantReducer = {
  [researchAssistantApi.reducerPath]: researchAssistantApi.reducer,
  researchAssistant: researchAssistantSlice.reducer,
};
