export type SseEvent = {
  type: "update" | "create" | "delete";
  entity: SseEntities;
  project_id: string;
  data: unknown;
  timestamp: Date;
};

export enum SseEntities {
  SYNTHETIC_USER = "SyntheticUser",
  STUDY = "Study",
  INTERVIEW = "Interview",
  CONVERSATION = "Conversation",
  SUMMARY = "Summary",
  KNOWLEDGE_GRAPH = "KnowledgeGraph",
  PLAN = "Plan",
  SUB_PLAN = "SubPlan",
  SUGGESTION = "Suggestion",
  REPORT = "Report",
  FILE = "File",
}
