// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { File, PaginatedFiles, UploadFile } from "./types";
import { RcFile } from "antd/es/upload";
// Schemas
import { FileSchema, PaginatedFilesSchema, UploadFileSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const filesApi = createApi({
  reducerPath: "filesApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Files"],
  endpoints: (builder) => ({
    /***** --- Get Files Query --- *****/
    getFiles: builder.query<PaginatedFiles, { ids?: string[]; projectId?: string }>({
      query: ({ ids, projectId }) => ({
        type: "sdk",
        method: sdkApis.files.listFiles(ids?.join(","), projectId),
      }),
      transformResponse: (response: PaginatedFiles) => {
        return {
          ...response,
          items: response.items.sort(
            (a, b) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
          ),
        };
      },
      providesTags: ["Files"],
      extraOptions: {
        dataSchema: PaginatedFilesSchema,
      },
    }),
    /***** --- Upload File Mutation --- *****/
    uploadFile: builder.mutation<
      UploadFile,
      {
        projectId: string;
        file: RcFile;
        userEmail: string;
        onSuccess: () => void;
        onError: () => void;
      }
    >({
      query: ({ projectId, file }) => ({
        type: "sdk",
        method: sdkApis.files.createFile({ projectId, filename: file.name }),
      }),
      async onQueryStarted({ file, onSuccess, onError }, { dispatch, queryFulfilled, requestId }) {
        try {
          const { data: uploadRagFile } = await queryFulfilled;

          const { url, fields } = uploadRagFile.presigned;

          const formData = new FormData();

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });

          formData.append("file", file);

          const upload = await fetch(url, {
            method: "POST",
            body: formData,
          });

          if (upload.ok) {
            dispatch(
              filesApi.endpoints.markUploadAsCompleted.initiate({
                fileId: uploadRagFile.id,
                temporaryId: requestId,
                onSuccess,
                onError,
              })
            );
          } else {
            onError();
            // throw error to be handled by the reducer
            throw new Error("Failed to upload file");
          }
        } catch (error) {
          onError();
          // throw error to be handled by the reducer
          throw error;
        }
      },
      extraOptions: {
        dataSchema: UploadFileSchema,
      },
    }),
    /***** --- Mark Upload As Completed Mutation --- *****/
    markUploadAsCompleted: builder.mutation<
      File,
      { fileId: string; temporaryId: string; onSuccess: () => void; onError: () => void }
    >({
      query: ({ fileId }) => ({
        type: "sdk",
        method: sdkApis.files.startProcessing(fileId),
      }),
      async onQueryStarted({ onSuccess, onError }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          onSuccess();
        } catch (error) {
          onError();
          // throw error to be handled by the reducer
          throw error;
        }
      },
      extraOptions: {
        dataSchema: FileSchema,
      },
    }),
    /***** --- Delete File Mutation --- *****/
    deleteFile: builder.mutation<void, { fileId: string }>({
      query: ({ fileId }) => ({
        type: "sdk",
        method: sdkApis.files.deleteFile(fileId),
      }),
    }),
  }),
});

// Create the regular slice
export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    /***** --- Reset Files --- *****/
    resetFiles: () => initialState,
    /***** --- Set Selected Files --- *****/
    setSelectedFiles: (state, action: PayloadAction<string[]>) => {
      if (!state.data) return state;

      state.data.selectedFiles =
        state.data.files?.filter((file) => action.payload.includes(file.id)) || [];
    },
    /***** --- Reset Selected Files --- *****/
    resetSelectedFiles: (state) => {
      if (!state.data) return state;

      state.data.selectedFiles = [];
    },
    /***** --- Update Files Sse --- *****/
    updateFilesSse: (state, action: PayloadAction<File>) => {
      if (!state.data) return state;

      state.data.files = state.data.files?.map((file) =>
        file.id === action.payload.id ? action.payload : file
      );
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          filesApi.endpoints.getFiles.matchPending,
          filesApi.endpoints.uploadFile.matchPending,
          filesApi.endpoints.deleteFile.matchPending,
          filesApi.endpoints.markUploadAsCompleted.matchPending
        ),
        (state) => {
          state.loading += 1;
        }
      )
      .addMatcher(
        isAnyOf(
          filesApi.endpoints.getFiles.matchFulfilled,
          filesApi.endpoints.getFiles.matchRejected,
          filesApi.endpoints.uploadFile.matchFulfilled,
          filesApi.endpoints.uploadFile.matchRejected,
          filesApi.endpoints.deleteFile.matchFulfilled,
          filesApi.endpoints.deleteFile.matchRejected,
          filesApi.endpoints.markUploadAsCompleted.matchFulfilled,
          filesApi.endpoints.markUploadAsCompleted.matchRejected
        ),
        (state) => {
          state.loading -= 1;
        }
      )
      /***** --- Handle Get Files Fulfilled --- *****/
      .addMatcher(filesApi.endpoints.getFiles.matchFulfilled, (state, action) => {
        state.data = {
          files: action.payload.items,
          selectedFiles: state.data?.selectedFiles || [],
        };
      })
      /***** --- Handle Upload File Pending --- *****/
      .addMatcher(filesApi.endpoints.uploadFile.matchPending, (state, action) => {
        const { requestId } = action.meta;
        const { file, userEmail, projectId } = action.meta.arg.originalArgs;
        // create a temporary file with the requestId as the id
        const temporaryFile = {
          id: requestId,
          filename: file.name,
          projectId,
          uploadedBy: userEmail,
          stats: null,
          uploadedAt: new Date().toISOString(),
          status: "uploading",
        };

        // add the temporary file to the files
        state.data = {
          files: [temporaryFile, ...(state.data?.files || [])],
          selectedFiles: state.data?.selectedFiles || [],
        };
      })
      /***** --- Handle Upload File Rejected --- *****/
      .addMatcher(filesApi.endpoints.uploadFile.matchRejected, (state, action) => {
        const { requestId } = action.meta;

        // remove the temporary file from the files
        state.data = {
          files: [...(state.data?.files || []).filter((file) => file.id !== requestId)],
          selectedFiles: state.data?.selectedFiles || [],
        };
      })
      /***** --- Handle Upload File Completed --- *****/
      .addMatcher(filesApi.endpoints.markUploadAsCompleted.matchFulfilled, (state, action) => {
        const { temporaryId } = action.meta.arg.originalArgs;

        // update the temporary file with the completed file
        state.data = {
          files: [
            ...(state.data?.files || []).map((file) =>
              file.id === temporaryId ? action.payload : file
            ),
          ],
          selectedFiles: state.data?.selectedFiles || [],
        };
      })
      /***** --- Handle Upload File Rejected --- *****/
      .addMatcher(filesApi.endpoints.markUploadAsCompleted.matchRejected, (state, action) => {
        const { temporaryId } = action.meta.arg.originalArgs;

        // remove the temporary file from the files
        state.data = {
          files: [...(state.data?.files || []).filter((file) => file.id !== temporaryId)],
          selectedFiles: state.data?.selectedFiles || [],
        };
      })
      /***** --- Handle Delete File Fulfilled --- *****/
      .addMatcher(filesApi.endpoints.deleteFile.matchFulfilled, (state, action) => {
        const { fileId } = action.meta.arg.originalArgs;
        if (state.data?.files) {
          state.data.files = state.data.files.filter((file) => file.id !== fileId);
        }
      })
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          filesApi.endpoints.getFiles.matchRejected,
          filesApi.endpoints.uploadFile.matchRejected,
          filesApi.endpoints.deleteFile.matchRejected,
          filesApi.endpoints.markUploadAsCompleted.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetFiles, setSelectedFiles, resetSelectedFiles, updateFilesSse } =
  filesSlice.actions;

// Export hooks
export const {
  useGetFilesQuery,
  useUploadFileMutation,
  useMarkUploadAsCompletedMutation,
  useDeleteFileMutation,
  useLazyGetFilesQuery,
} = filesApi;

// Combine the reducers
export const filesReducer = {
  [filesApi.reducerPath]: filesApi.reducer,
  files: filesSlice.reducer,
};
