import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const AudiencesSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  createdAt: z.union([z.date(), z.string()]),
});

export const PaginatedAudiencesSchema = createPaginatedResultsSchema(AudiencesSchema);
