import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const StudySchema = z.object({
  id: z.string(),
  description: z.string().nullable(),
  projectId: z.string(),
  bookmarked: z.boolean(),
  language: z.string(),
  audiences: z.array(z.string()),
  problems: z.array(z.string()),
  files: z.array(z.string()),
  planId: z.string().nullable(),
  status: z.string(),
  createdAt: z.date(),
  // createdBy: z.string().nullable(),
  solutionId: z.string().nullable(),
  researchGoalId: z.string().nullable(),
  strategy: z.string(),
  interviews: z.array(z.string()),
  summaries: z.array(z.string()),
  knowledgeGraphs: z.array(z.string()),
  progress: z
    .object({
      total: z.number(),
      completed: z.number(),
      failed: z.number().optional(),
    })
    .optional()
    .nullable(),
});

export const PaginatedStudiesSchema = createPaginatedResultsSchema(StudySchema);
