import { studyPlansApi } from "@/store/modules/study-plans/slice";
import { store } from "../..";
import { audiencesApi, resetSelectedAudiences } from "../../modules/audiences/slice";
import { resetCustomScript } from "../../modules/custom-script/slice";
import { resetKnowledgeGraph } from "../../modules/knowledge-graph/slice";
import { Project } from "../../modules/projects/types";
import { filesApi } from "../../modules/files/slice";
import { resetResearchAssistant } from "../../modules/research-assistant/slice";
import { resetResearchGoal } from "../../modules/research-goal/slice";
import { resetCurrentStudy, studiesApi } from "../../modules/studies/slice";
import { resetSummary } from "../../modules/summaries/slice";
import { resetSyntheticUsers } from "../../modules/synthetic-users/slice";
import { resetUserInterviews } from "../../modules/user-interviews/slice";
import { resetHistory } from "@/store/modules/history/slice";

export const handleChangeProject = (project: Project) => {
  store.dispatch(resetSelectedAudiences());
  store.dispatch(
    audiencesApi.endpoints.getProjectAudiences.initiate(
      { projectId: project.id },
      { forceRefetch: true }
    )
  );
  store.dispatch(
    studiesApi.endpoints.getStudies.initiate(
      { projectId: project.id, page: 1, pageSize: 3 },
      { forceRefetch: true }
    )
  );
  store.dispatch(
    filesApi.endpoints.getFiles.initiate({ projectId: project.id }, { forceRefetch: true })
  );
  store.dispatch(
    studyPlansApi.endpoints.getPlansList.initiate(
      { projectId: project.id, page: 1, pageSize: 3 },
      { forceRefetch: true }
    )
  );

  // reset data that is not included in the project object
  store.dispatch(resetResearchAssistant());
  store.dispatch(resetCustomScript());
  store.dispatch(resetResearchGoal());
  store.dispatch(resetCurrentStudy());
  store.dispatch(resetSyntheticUsers());
  store.dispatch(resetUserInterviews());
  store.dispatch(resetSummary());
  store.dispatch(resetKnowledgeGraph());
  store.dispatch(resetHistory());
};
