import { z } from "zod";

export type PaginatedResults<T> = {
  items: T[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export const createPaginatedResultsSchema = <T extends z.ZodType>(itemSchema: T) =>
  z.object({
    items: z.array(itemSchema),
    total: z.number(),
    page: z.number(),
    size: z.number(),
    pages: z.number(),
  });
