import { z } from "zod";

export enum KnowledgeGraphStatus {
  CREATED = "created",
  FAILED = "failed",
  RUNNING = "running",
  DONE = "done",
}

export const KnowledgeGraphSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  projectId: z.string(),
  studyId: z.string(),
  status: z.nativeEnum(KnowledgeGraphStatus),
  graphData: z.string().nullable(),
  explanation: z.string().nullable(),
});
