// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { CurrentUser, PaginatedUsers } from "./types";
// Schemas
import { CurrentUserSchema, PaginatedUsersSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query<PaginatedUsers, { workspaceId: string }>({
      query: ({ workspaceId }) => ({
        type: "sdk",
        method: sdkApis.users.listUsers(undefined, workspaceId),
      }),
      extraOptions: {
        dataSchema: PaginatedUsersSchema,
      },
    }),
    /***** --- Get Current User Query --- *****/
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        type: "fetch",
        url: "/users/current",
      }),
      extraOptions: {
        dataSchema: CurrentUserSchema,
      },
    }),
    /***** --- Create User Mutation --- *****/
    createUser: builder.query<CurrentUser, void>({
      query: () => ({
        type: "fetch",
        url: "/users/create",
      }),
      extraOptions: {
        dataSchema: CurrentUserSchema,
      },
    }),
    /***** --- Validate Email Mutation --- *****/
    validateEmail: builder.mutation<CurrentUser, void>({
      query: () => ({
        type: "fetch",
        url: "/users/validate-email",
        method: "POST",
      }),
      extraOptions: {
        dataSchema: CurrentUserSchema,
        refetchToken: true,
      },
    }),
  }),
});

// Create the regular slice
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    /***** --- Reset Current User --- *****/
    resetCurrentUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          usersApi.endpoints.getCurrentUser.matchPending,
          usersApi.endpoints.createUser.matchPending,
          usersApi.endpoints.validateEmail.matchPending,
          usersApi.endpoints.getUsers.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      /***** --- Handle Get Current User Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          usersApi.endpoints.getCurrentUser.matchFulfilled,
          usersApi.endpoints.createUser.matchFulfilled,
          usersApi.endpoints.validateEmail.matchFulfilled
        ),
        (state, action) => {
          state.data.currentUser = action.payload;
          state.loading = false;
        }
      )
      /***** --- Handle Get Users Fulfilled --- *****/
      .addMatcher(isAnyOf(usersApi.endpoints.getUsers.matchFulfilled), (state, action) => {
        state.data.users = action.payload.items;
        state.loading = false;
      })
      /***** --- Handle Rejected --- *****/
      .addMatcher(
        isAnyOf(
          usersApi.endpoints.getCurrentUser.matchRejected,
          usersApi.endpoints.createUser.matchRejected,
          usersApi.endpoints.validateEmail.matchRejected,
          usersApi.endpoints.getUsers.matchRejected
        ),
        (state, action) => {
          state.loading = false;
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetCurrentUser } = usersSlice.actions;

// export hooks
export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useLazyCreateUserQuery,
  useValidateEmailMutation,
  useLazyGetUsersQuery,
} = usersApi;

// Combine the reducers
export const usersReducer = {
  [usersApi.reducerPath]: usersApi.reducer,
  users: usersSlice.reducer,
};
