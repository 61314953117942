// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { ResearchGoal } from "./types";
// Schemas
import { ResearchGoalSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const researchGoalApi = createApi({
  reducerPath: "researchGoalApi",
  baseQuery: customBaseQuery,
  tagTypes: ["ResearchGoal"],
  endpoints: (builder) => ({
    /***** --- Get Research Goal By Id Query --- *****/
    getResearchGoalById: builder.query<ResearchGoal, { researchGoalId: string }>({
      query: ({ researchGoalId }) => ({
        type: "sdk",
        method: sdkApis.researchGoals.getResearchGoal(researchGoalId),
      }),
      extraOptions: {
        dataSchema: ResearchGoalSchema,
      },
    }),
    /***** --- Add Research Goal Mutation --- *****/
    addResearchGoal: builder.mutation<ResearchGoal, { projectId: string; description: string }>({
      query: ({ projectId, description }) => ({
        type: "sdk",
        method: sdkApis.researchGoals.createResearchGoals({
          projectId,
          description,
        }),
      }),
      extraOptions: {
        dataSchema: ResearchGoalSchema,
      },
    }),
    /***** --- Update Research Goal Mutation --- *****/
    updateResearchGoal: builder.mutation<
      ResearchGoal,
      { researchGoalId: string; description: string }
    >({
      query: ({ researchGoalId, description }) => ({
        type: "sdk",
        method: sdkApis.researchGoals.editResearchGoals(researchGoalId, {
          description,
        }),
      }),
      extraOptions: {
        dataSchema: ResearchGoalSchema,
      },
    }),
  }),
});

// Create the regular slice
export const researchGoalSlice = createSlice({
  name: "researchGoal",
  initialState,
  reducers: {
    /***** --- Reset Research Goal --- *****/
    resetResearchGoal: () => initialState,
    /***** --- Set Research Goal --- *****/
    setResearchGoal: (state, action: PayloadAction<ResearchGoal>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Get Research Goal By Id Pending --- *****/
      .addMatcher(researchGoalApi.endpoints.getResearchGoalById.matchPending, (state) => {
        state.loading = true;
        state.loading = false;
      })
      /***** --- Handle Get Research Goal By Id Fullfilled --- *****/
      .addMatcher(researchGoalApi.endpoints.getResearchGoalById.matchFulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      /***** --- Handle Add Research Goal Pending --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchPending, (state, action) => {
        const { requestId } = action.meta;
        const { description } = action.meta.arg.originalArgs;
        // Create an optimistic research goal
        const optimisticResearchGoal = {
          id: requestId,
          description,
          createdAt: new Date().toISOString(),
        };
        // Set the optimistic research goal
        state.data = optimisticResearchGoal;
        state.loading = true;
      })
      /***** --- Handle Add Research Goal Fulfilled --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchFulfilled, (state, action) => {
        const completedResearchGoal = action.payload;
        // Set the completed research goal
        state.data = completedResearchGoal;
        state.loading = false;
      })
      /***** --- Handle Update Research Goal Fulfilled --- *****/
      .addMatcher(researchGoalApi.endpoints.updateResearchGoal.matchFulfilled, (state, action) => {
        // get the research goal id to update
        const researchGoalToUpdateId = action.meta.arg.originalArgs.researchGoalId;
        const updatedResearchGoal = action.payload;

        // Update the research goal with the server data
        state.data = state.data?.id === researchGoalToUpdateId ? updatedResearchGoal : state.data;
      })
      /***** --- Handle Add Research Goal Rejected --- *****/
      .addMatcher(researchGoalApi.endpoints.addResearchGoal.matchRejected, (state, action) => {
        state.error = parseError(action.error);
        state.loading = false;
      });
  },
});

// Export actions
export const { setResearchGoal, resetResearchGoal } = researchGoalSlice.actions;

// Export hooks
export const {
  useAddResearchGoalMutation,
  useGetResearchGoalByIdQuery,
  useUpdateResearchGoalMutation,
  useLazyGetResearchGoalByIdQuery,
} = researchGoalApi;

// Combine the reducers
export const researchGoalReducer = {
  [researchGoalApi.reducerPath]: researchGoalApi.reducer,
  researchGoal: researchGoalSlice.reducer,
};
