import { z } from "zod";
import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";

export const InterviewAnnotationSchema = z.object({
  id: z.string(),
  conversationId: z.string(),
  userInterviewId: z.string(),
  annotation: z.string(),
  startIndex: z.number(),
  endIndex: z.number(),
  createdAt: z.date(),
  label: z.string().nullable(),
});

export const InterviewConversationSchema = z.object({
  id: z.string(),
  type: z.string(),
  message: z.string(),
  topic: z.string().nullable(),
  initial: z.boolean(),
  createdAt: z.date(),
  interviewId: z.string(),
  annotations: z.array(InterviewAnnotationSchema).nullable().optional(),
});

export const UserInterviewSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  studyId: z.string().nullable(),
  createdAt: z.date(),
  status: z.string(),
  processedAt: z.date().nullable(),
  helpful: z.boolean().nullable(),
  syntheticUserId: z.string(),
  audienceId: z.string(),
  topics: z.array(z.string()),
  conversations: z.array(InterviewConversationSchema),
});

export const PaginatedUserInterviewsSchema = createPaginatedResultsSchema(UserInterviewSchema);
