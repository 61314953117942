// sseMiddleware.ts
import { Dispatch, Middleware, UnknownAction, MiddlewareAPI, createAction } from "@reduxjs/toolkit";
import { hasPayloadResourceId, hasType } from "./typeguards";
import { RootState } from "@/store";
import { connectEventSource } from "./connect-event-source";

export const sseConnectProjectEvents = createAction<{ resourceId: string }>(
  "sse/connectProjectEvents"
);

export const sseMiddleware = (): Middleware => {
  let abortController: AbortController | null = null;
  let isSseConnected = false;

  const toggleSseConnection = (connection: boolean) => {
    isSseConnected = connection;
  };

  return (store: MiddlewareAPI<Dispatch<UnknownAction>, RootState>) => (next) => (action) => {
    if (!hasType(action)) {
      return next(action);
    }

    switch (action.type) {
      case sseConnectProjectEvents.type: {
        if (!hasPayloadResourceId(action)) {
          return next(action);
        }

        if (isSseConnected) {
          abortController?.abort();
          abortController = null;
          isSseConnected = false;
        }

        const { resourceId } = action.payload;

        abortController = new AbortController();

        // Open a new SSE connection
        connectEventSource(resourceId, store, abortController, toggleSseConnection);

        next(action);
        break;
      }

      default:
        next(action);
    }
  };
};
