import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const CurrentUserSchema = z.object({
  id: z.string(),
  firebaseId: z.string(),
  email: z.union([z.string(), z.null()]),
  apiKey: z.union([z.string(), z.null()]),
  lastVisitedProject: z.union([z.string(), z.null()]),
  lastVisitedWorkspace: z.union([z.string(), z.null()]),
  emailValidated: z.boolean(),
});

export const Userschema = z.object({
  id: z.string(),
  email: z.string(),
  emailValidated: z.boolean(),
  createdAt: z.date(),
  generatedCounter: z.number(),
});

export const PaginatedUsersSchema = createPaginatedResultsSchema(Userschema);
