import { SseEntities } from "./types";
import { RootState } from "@/store";

import { EnvironmentVariables } from "@/logic/internals/runtime/environment-variables";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Dispatch, MiddlewareAPI, UnknownAction } from "@reduxjs/toolkit";
import { SseEvent } from "./types";
import { SyntheticUser } from "@/store/modules/synthetic-users/types";
import { updateSyntheticUsersFromSse } from "@/store/modules/synthetic-users/slice";
import { Study } from "@/store/modules/studies/types";
import { updateStudiesSse } from "@/store/modules/studies/slice";
import { historyApi } from "@/store/modules/history/slice";
import { InterviewConversation, UserInterview } from "@/store/modules/user-interviews/types";
import {
  addUserInterviewConversations,
  updateUserInterviewsSse,
} from "@/store/modules/user-interviews/slice";
import {
  updateStudyPlanSse,
  updateStudySubPlanReportSse,
  updateStudySubPlanSse,
  updateStudySubPlanSuggestionSse,
} from "@/store/modules/study-plans/slice";
import { updateKnowledgeGraphSse } from "@/store/modules/knowledge-graph/slice";
import { KnowledgeGraph } from "@/store/modules/knowledge-graph/types";
import { StudySubPlan, SubPlanReport, Suggestion } from "@/store/modules/study-plans/types";
import { StudyPlan } from "@/store/modules/study-plans/types";
import { Summary } from "@/store/modules/summaries/types";
import { updateSummariesSse } from "@/store/modules/summaries/slice";
import { updateFilesSse } from "@/store/modules/files/slice";
import { File } from "@/store/modules/files/types";
import { retry } from "@/logic/internals/utils/exponential-backoff";
import {
  setSseProjectEventsConnected,
  setSseProjectEventsDisconnected,
} from "@/store/modules/projects/slice";
import { Logger } from "@/logic/internals/logging/logger";

export const connectEventSource = (
  resourceId: string,
  store: MiddlewareAPI<Dispatch<UnknownAction>, RootState>,
  abortController: AbortController | null,
  toggleSseConnection?: (connection: boolean) => void
) => {
  const token = store.getState().users.data?.currentUser?.apiKey;

  if (!token) return;

  // Open a new SSE connection
  fetchEventSource(`${EnvironmentVariables.MAIN_API_URL}/api/v1/projects/${resourceId}/stream`, {
    headers: {
      Accept: "text/event-stream",
      Authorization: `Bearer ${token}`,
    },
    signal: abortController?.signal,
    openWhenHidden: true,
    onclose: () => {
      abortController = null;
      // On close, retry the connection 7 times with exponential backoff (+- 30 seconds)
      retry(
        () =>
          new Promise<void>((resolve, reject) => {
            try {
              connectEventSource(resourceId, store, abortController);
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
        () =>
          new Promise<void>((resolve, reject) => {
            try {
              connectEventSource(resourceId, store, abortController);
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
        7,
        () => {
          Logger.logError("connectEventSource:onclose", "Connection failed after 7 retries");
          toggleSseConnection?.(false);
          store.dispatch(setSseProjectEventsDisconnected());
        }
      );
    },
    onopen: async () => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      toggleSseConnection?.(true);
      store.dispatch(setSseProjectEventsConnected());
    },
    onerror: (error) => {
      Logger.logError("connectEventSource:onerror", error);
      abortController = null;
      // On close, retry the connection 7 times with exponential backoff (+- 30 seconds)
      retry(
        () =>
          new Promise<void>((resolve, reject) => {
            try {
              connectEventSource(resourceId, store, abortController);
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
        () =>
          new Promise<void>((resolve, reject) => {
            try {
              connectEventSource(resourceId, store, abortController);
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
        7,
        () => {
          Logger.logError("connectEventSource:onclose", "Connection failed after 7 retries");
          toggleSseConnection?.(false);
          store.dispatch(setSseProjectEventsDisconnected());
        }
      );
    },
    onmessage: (event) => {
      const currentProjectId = store.getState().projects.data?.project?.id;
      const currentStudy = store.getState().studies.data?.currentStudy;
      const currentAudienceId = store.getState().audiences.data?.selectedAudiences?.[0]?.id;
      const currentStudyPlan = store.getState().studyPlans.data?.studyPlan;

      const data = JSON.parse(event.data) as SseEvent;

      // If the event is not for the current project, skip it
      if (data.project_id !== currentProjectId) return;

      switch (data.entity) {
        case SseEntities.SYNTHETIC_USER: {
          const syntheticUser = data.data as SyntheticUser;

          // If the synthetic user is not being generated for the current audience, skip it
          if (syntheticUser.audienceId === currentAudienceId) {
            store.dispatch(
              updateSyntheticUsersFromSse({ syntheticUser, timestamp: data.timestamp })
            );
          }

          break;
        }
        case SseEntities.STUDY: {
          const study = data.data as Study;

          store.dispatch(updateStudiesSse({ study, timestamp: data.timestamp }));

          // Refresh history when a study finishes
          if (study.status === "done") {
            store.dispatch(
              historyApi.endpoints.getHistory.initiate({
                projectId: currentProjectId,
              }) as unknown as UnknownAction
            );
          }

          break;
        }
        case SseEntities.INTERVIEW: {
          const userInterview = data.data as UserInterview;

          if (currentStudy?.id === userInterview.studyId) {
            store.dispatch(updateUserInterviewsSse({ userInterview, timestamp: data.timestamp }));
          }
          break;
        }
        case SseEntities.CONVERSATION: {
          const conversation = data.data as InterviewConversation;
          // If the conversation is not in the current study, skip it
          if (
            data.type === "create" &&
            currentStudy?.interviews.includes(conversation.interviewId)
          ) {
            store.dispatch(
              addUserInterviewConversations({ conversation, timestamp: data.timestamp })
            );
          }
          break;
        }
        case SseEntities.KNOWLEDGE_GRAPH: {
          const knowledgeGraph = data.data as KnowledgeGraph;
          // If the knowledge graph is not in the current study, skip it
          if (knowledgeGraph.studyId === currentStudy?.id) {
            store.dispatch(updateKnowledgeGraphSse({ knowledgeGraph, timestamp: data.timestamp }));
          }

          break;
        }
        case SseEntities.SUMMARY: {
          const summary = data.data as Summary;
          // If the summary is not in the current study, skip it
          if (summary.studyId === currentStudy?.id) {
            store.dispatch(updateSummariesSse({ summary, timestamp: data.timestamp }));
          }

          break;
        }
        case SseEntities.PLAN: {
          const studyPlan = data.data as StudyPlan;

          if (studyPlan.id === currentStudyPlan?.id) {
            store.dispatch(updateStudyPlanSse({ studyPlan, timestamp: data.timestamp }));
          }
          break;
        }
        case SseEntities.SUB_PLAN: {
          const studySubPlan = data.data as StudySubPlan;

          if (currentStudyPlan?.subplans.find((sPlan) => sPlan.id === studySubPlan.id)) {
            store.dispatch(updateStudySubPlanSse({ studySubPlan, timestamp: data.timestamp }));
          }

          break;
        }
        case SseEntities.SUGGESTION: {
          const suggestion = data.data as Suggestion;

          if (currentStudyPlan?.subplans.find((subPlan) => subPlan.id === suggestion.subplanId)) {
            store.dispatch(
              updateStudySubPlanSuggestionSse({ suggestion, timestamp: data.timestamp })
            );
          }

          break;
        }
        case SseEntities.REPORT: {
          const subPlanReport = data.data as SubPlanReport;
          if (
            currentStudyPlan?.subplans.find((subPlan) => subPlan.id === subPlanReport.subplanId)
          ) {
            store.dispatch(
              updateStudySubPlanReportSse({ subPlanReport, timestamp: data.timestamp })
            );
          }
          break;
        }
        case SseEntities.FILE: {
          const file = data.data as File;

          if (data.type === "update") {
            store.dispatch(updateFilesSse(file));
          }
          break;
        }
      }
    },
  });
};
