import {
  createConfiguration,
  AudiencesApi,
  ResearchGoalsApi,
  WorkspacesApi,
  ProjectsApi,
  ProblemsApi,
  SolutionsApi,
  FilesApi,
  SyntheticUsersApi,
  StudiesApi,
  PlansApi,
  SubPlansApi,
  SuggestionsApi,
  InterviewsApi,
  AssistantSessionsApi,
  KnowledgeGraphsApi,
  SummariesApi,
  ConversationsApi,
  AnnotationsApi,
  ServerConfiguration,
  UsersApi,
} from "@synthetic-users/api-sdk";

import { store } from "..";
import { EnvironmentVariables } from "@/logic/internals/runtime/environment-variables";

// Create a token provider that returns the API key
const tokenProvider = {
  getToken: () => {
    // Get the API key from your store
    const apiKey = store.getState().users.data?.currentUser?.apiKey;
    const queryParams = new URLSearchParams(window.location.search);
    // Get isShared param
    const isShared = queryParams.get("shared");

    if (isShared) {
      return Promise.resolve(
        "su_yROLXkPZHAhLswWKKtRSzhHFLZhHmzxDv3J4lN6QK6PrPLoWS65JaTczZe9xqz5GMkw"
      );
    }

    if (apiKey) {
      return Promise.resolve(apiKey);
    }

    throw new Error("No API key available");
  },
};

// Create custom server configuration with your base URL
const customServer = new ServerConfiguration(
  `${EnvironmentVariables.MAIN_API_URL}/api/v1`,
  {} // Variable configuration if needed
);

// Create auth configuration
const authConfig = {
  HTTPBearer: {
    tokenProvider,
  },
};

// Create SDK configuration with authentication
const configuration = createConfiguration({
  authMethods: authConfig,
  baseServer: customServer,
});

// Create SDK instances
const audiencesApiInstance = new AudiencesApi(configuration);
const researchGoalsApiInstance = new ResearchGoalsApi(configuration);
const workspacesApiInstance = new WorkspacesApi(configuration);
const projectsApiInstance = new ProjectsApi(configuration);
const problemsApiInstance = new ProblemsApi(configuration);
const solutionsApiInstance = new SolutionsApi(configuration);
const filesApiInstance = new FilesApi(configuration);
const syntheticUsersApiInstance = new SyntheticUsersApi(configuration);
const studiesApiInstance = new StudiesApi(configuration);
const plansApiInstance = new PlansApi(configuration);
const subPlansApiInstance = new SubPlansApi(configuration);
const suggestionsApiInstance = new SuggestionsApi(configuration);
const interviewsApiInstance = new InterviewsApi(configuration);
const researchAssistantApiInstance = new AssistantSessionsApi(configuration);
const knowledgeGraphsApiInstance = new KnowledgeGraphsApi(configuration);
const summariesApiInstance = new SummariesApi(configuration);
const conversationsApiInstance = new ConversationsApi(configuration);
const annotationsApiInstance = new AnnotationsApi(configuration);
const usersApiInstance = new UsersApi(configuration);
// Export SDK instances
export const sdkApis = {
  audiences: audiencesApiInstance,
  researchGoals: researchGoalsApiInstance,
  workspaces: workspacesApiInstance,
  projects: projectsApiInstance,
  problems: problemsApiInstance,
  solutions: solutionsApiInstance,
  files: filesApiInstance,
  syntheticUsers: syntheticUsersApiInstance,
  studies: studiesApiInstance,
  plans: plansApiInstance,
  subPlans: subPlansApiInstance,
  suggestions: suggestionsApiInstance,
  interviews: interviewsApiInstance,
  researchAssistant: researchAssistantApiInstance,
  knowledgeGraph: knowledgeGraphsApiInstance,
  summaries: summariesApiInstance,
  conversations: conversationsApiInstance,
  annotations: annotationsApiInstance,
  users: usersApiInstance,
};
