/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 */
export function waitFor(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param {Promise} promise promise to be executed
 * @param {function} onRetry callback executed on every retry
 * @param {number} maxRetries The maximum number of retries to be attempted
 *  * @param {function} onMaxRetriesExhausted callback executed when all retries have failed
 * @returns {Promise} The result of the given promise passed in
 */
export function retry<T>(
  promise: () => Promise<T>,
  onRetry: () => void,
  maxRetries: number,
  onMaxRetriesExhausted?: () => void
): Promise<T> {
  async function retryWithBackoff(retries: number): Promise<T> {
    try {
      if (retries > 0) {
        const timeToWait = 2 ** retries * 100;
        await waitFor(timeToWait);
      }
      return await promise();
    } catch (e) {
      if (retries < maxRetries) {
        onRetry();
        return await retryWithBackoff(retries + 1);
      } else {
        onMaxRetriesExhausted?.();
        throw e;
      }
    }
  }

  return retryWithBackoff(0);
}
