// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { SyntheticUser, PaginatedSyntheticUsers } from "./types";
// Schemas
import { SyntheticUserSchema, PaginatedSyntheticUsersSchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const syntheticUsersApi = createApi({
  reducerPath: "syntheticUsersApi",
  baseQuery: customBaseQuery,
  tagTypes: ["SyntheticUsers"],
  endpoints: (builder) => ({
    /***** --- Generate Synthetic Users Mutation --- *****/
    generateSyntheticUsers: builder.mutation<
      string[],
      {
        quantity: number;
        audienceId: string;
        problems: string[];
        researchGoalId?: string;
        uploadedFilesIds?: string[];
      }
    >({
      query: ({ quantity, audienceId, problems, researchGoalId, uploadedFilesIds }) => ({
        type: "sdk",
        method: sdkApis.audiences.generateAudience(audienceId, {
          quantity,
          problems,
          researchGoalId: researchGoalId ?? null,
          files: uploadedFilesIds ?? null,
        }),
      }),
    }),
    /***** --- Get Synthetic Users Query --- *****/
    getSyntheticUsers: builder.query<PaginatedSyntheticUsers, { ids: string[] }>({
      query: ({ ids }) => ({
        type: "sdk",
        method: sdkApis.syntheticUsers.listSyntheticUsers(ids.join(",")),
      }),
      extraOptions: {
        dataSchema: PaginatedSyntheticUsersSchema,
      },
    }),
    /***** --- Update Synthetic Users Mutation --- *****/
    updateSyntheticUsers: builder.mutation<
      SyntheticUser,
      {
        syntheticUserId: string;
        data: string;
      }
    >({
      query: ({ syntheticUserId, data }) => ({
        type: "sdk",
        method: sdkApis.syntheticUsers.editSyntheticUser(syntheticUserId, {
          description: data,
        }),
      }),
      extraOptions: {
        dataSchema: SyntheticUserSchema,
      },
    }),
  }),
});

// Create the regular slice
export const syntheticUsersSlice = createSlice({
  name: "syntheticUsers",
  initialState,
  reducers: {
    /***** --- Reset Synthetic Users --- *****/
    resetSyntheticUsers: () => initialState,
    /***** --- Update Synthetic Users from SSE --- *****/
    updateSyntheticUsersFromSse: (
      state,
      action: PayloadAction<{ syntheticUser: SyntheticUser; timestamp: Date }>
    ) => {
      const { syntheticUser, timestamp } = action.payload;
      const syntheticUserExists = state.data?.syntheticUsers?.find(
        (item) => item.id === syntheticUser.id
      );

      const allSyntheticUsersGenerated = state.data?.syntheticUsers?.every(
        (item) => item.status === "ready"
      );

      state.data = {
        ...state.data,
        syntheticUsers: syntheticUserExists
          ? state.data?.syntheticUsers?.map((item) =>
              item.id === syntheticUser.id ? syntheticUser : item
            )
          : [...(state.data?.syntheticUsers ?? []), syntheticUser],
        generatingIds: allSyntheticUsersGenerated ? undefined : state.data?.generatingIds,
      };
      state.lastSseEventTimestamp = timestamp;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchPending,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchFulfilled,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Generate Synthetic Users Fulfilled --- *****/
      .addMatcher(
        syntheticUsersApi.endpoints.generateSyntheticUsers.matchFulfilled,
        (state, action) => {
          state.data = {
            ...state.data,
            generatingIds: action.payload,
          };
        }
      )
      /***** --- Handle Get Synthetic Users Fulfilled --- *****/
      .addMatcher(syntheticUsersApi.endpoints.getSyntheticUsers.matchFulfilled, (state, action) => {
        const allUsersGenerated = action.payload.items.every((user) => user.status === "ready");

        // if payload is an array it means the synthetic users generation is done
        state.data = {
          syntheticUsers: action.payload.items,
          generatingIds: allUsersGenerated ? undefined : state.data?.generatingIds,
        };
        state.loading = false;
      })
      /***** --- Handle Update Synthetic Users Fulfilled --- *****/
      .addMatcher(
        syntheticUsersApi.endpoints.updateSyntheticUsers.matchFulfilled,
        (state, action) => {
          state.data = {
            ...state.data,
            syntheticUsers: state.data?.syntheticUsers?.map((item) =>
              item.id === action.payload.id ? action.payload : item
            ),
          };
        }
      )
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          syntheticUsersApi.endpoints.generateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.updateSyntheticUsers.matchRejected,
          syntheticUsersApi.endpoints.getSyntheticUsers.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetSyntheticUsers, updateSyntheticUsersFromSse } = syntheticUsersSlice.actions;

// Export hooks
export const {
  useGenerateSyntheticUsersMutation,
  useGetSyntheticUsersQuery,
  useLazyGetSyntheticUsersQuery,
  useUpdateSyntheticUsersMutation,
} = syntheticUsersApi;

// Combine the reducers
export const syntheticUsersReducer = {
  [syntheticUsersApi.reducerPath]: syntheticUsersApi.reducer,
  syntheticUsers: syntheticUsersSlice.reducer,
};
