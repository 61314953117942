import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const SummaryMessageSchema = z.object({
  id: z.string(),
  summaryId: z.string(),
  createdAt: z.date(),
  type: z.string(),
  message: z.string(),
});

export const SummarySchema = z.object({
  id: z.string(),
  content: z.string().nullable(),
  sections: z.array(
    z.object({
      id: z.string(),
      summaryId: z.string(),
      createdAt: z.date(),
      title: z.string(),
      content: z.string(),
    })
  ),
  projectId: z.string(),
  createdAt: z.date(),
  status: z.string(),
  userInterviews: z.array(z.string()),
  problems: z.array(z.string()),
  solution: z.string().nullable(),
  researchGoal: z.string().nullable(),
  audiences: z.array(z.string()),
  userInterviewsCount: z.number(),
  saved: z.boolean(),
  studyId: z.string().nullable(), // Shouldn't be nullable
  syntheticUsers: z.array(z.string()),
  messages: z.array(SummaryMessageSchema).optional(),
  summarizationFocus: z.string().nullable(),
});

export const PaginatedSummariesSchema = createPaginatedResultsSchema(SummarySchema);
