import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const FileSchema = z.object({
  id: z.string(),
  filename: z.string(),
  projectId: z.string(),
  uploadedBy: z.string(),
  stats: z
    .object({
      total: z.number(),
      current: z.number(),
    })
    .optional()
    .nullable(),
  uploadedAt: z.union([z.string(), z.date()]),
  status: z.string(),
});

export const PaginatedFilesSchema = createPaginatedResultsSchema(FileSchema);

export const UploadFileSchema = FileSchema.extend({
  presigned: z.object({
    url: z.string(),
    fields: z.record(z.string(), z.string()),
  }),
});
