// Redux toolkit store config
import { configureStore } from "@reduxjs/toolkit";
// Reducers and APIs
import { reducer as surveys } from "./modules/surveys/reducer";
import { customScriptReducer } from "./modules/custom-script/slice";
import { filesApi, filesReducer } from "./modules/files/slice";
import { audiencesApi, audiencesReducer } from "./modules/audiences/slice";
import { problemsApi, problemsReducer } from "./modules/problems/slice";
import { solutionsApi, solutionsReducer } from "./modules/solutions/slice";
import { researchGoalApi, researchGoalReducer } from "./modules/research-goal/slice";
import { historyApi, historyReducer } from "./modules/history/slice";
import { releaseNotesApi, releaseNotesReducer } from "./modules/release-notes/slice";
import { subscriptionApi, subscriptionReducer } from "./modules/subscription/slice";
import { usersApi, usersReducer } from "./modules/users/slice";
import { plansApi, plansReducer } from "./modules/plans/slice";
import { ssoProviderApi, ssoProviderReducer } from "./modules/sso-provider/slice";
import { researchAssistantApi, researchAssistantReducer } from "./modules/research-assistant/slice";
import { knowledgeGraphApi, knowledgeGraphReducer } from "./modules/knowledge-graph/slice";
import { userInterviewsApi, userInterviewsReducer } from "./modules/user-interviews/slice";

import { summaryApi, summaryReducer } from "./modules/summaries/slice";

import { syntheticUsersApi, syntheticUsersReducer } from "./modules/synthetic-users/slice";
import { studiesApi, studiesReducer } from "./modules/studies/slice";
import { projectsApi, projectsReducer } from "./modules/projects/slice";
import { workspacesApi, workspacesReducer } from "./modules/workspaces/slice";
import { studyPlansApi, studyPlansReducer } from "./modules/study-plans/slice";

import { sseMiddleware } from "./middleware/server-sent-events";

export const store = configureStore({
  reducer: {
    surveys,
    ...customScriptReducer,
    ...filesReducer,
    ...audiencesReducer,
    ...problemsReducer,
    ...solutionsReducer,
    ...researchGoalReducer,
    ...historyReducer,
    ...releaseNotesReducer,
    ...subscriptionReducer,
    ...usersReducer,
    ...plansReducer,
    ...ssoProviderReducer,
    ...researchAssistantReducer,
    ...knowledgeGraphReducer,
    ...userInterviewsReducer,
    ...summaryReducer,
    ...syntheticUsersReducer,
    ...studiesReducer,
    ...projectsReducer,
    ...workspacesReducer,
    ...studyPlansReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(filesApi.middleware)
      .concat(audiencesApi.middleware)
      .concat(problemsApi.middleware)
      .concat(solutionsApi.middleware)
      .concat(researchGoalApi.middleware)
      .concat(historyApi.middleware)
      .concat(releaseNotesApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(usersApi.middleware)
      .concat(plansApi.middleware)
      .concat(ssoProviderApi.middleware)
      .concat(researchAssistantApi.middleware)
      .concat(knowledgeGraphApi.middleware)
      .concat(userInterviewsApi.middleware)
      .concat(summaryApi.middleware)
      .concat(syntheticUsersApi.middleware)
      .concat(studiesApi.middleware)
      .concat(projectsApi.middleware)
      .concat(workspacesApi.middleware)
      .concat(studyPlansApi.middleware)
      .concat(sseMiddleware()),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
