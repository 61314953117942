import { z } from "zod";
import { StudySubPlanStatus, SuggestionStatus, SubPlanReportStatus } from "./types";
import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";

export const SuggestionSchema = z.object({
  id: z.string(),
  subplanId: z.string(),
  studyId: z.string().nullable(),
  type: z.string(),
  data: z.object({
    title: z.string().optional(),
    // TODO: remove optional
    type: z.enum(["adjacent", "sub-segment"]).optional(),
    priority: z.enum(["high", "medium", "low"]).optional(),
    reason: z.string().optional(),
    criteria: z.array(z.string()).optional(),
    goals: z.array(z.string()).optional(),
    dataPoints: z.array(z.string()).optional(),
  }),
  quantity: z.number(),
  createdAt: z.date(),
  status: z.nativeEnum(SuggestionStatus),
});

export const PaginatedSuggestionsSchema = createPaginatedResultsSchema(SuggestionSchema);

export const SubPlanReportSchema = z.object({
  id: z.string(),
  subplanId: z.string(),
  createdAt: z.date(),
  status: z.nativeEnum(SubPlanReportStatus),
  sections: z.array(
    z.object({
      title: z.string(),
      content: z.string(),
    })
  ),
  suggestions: z.array(z.string()),
});

export const StudySubPlanSchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  planId: z.string(),
  parentId: z.string().nullable(),
  status: z.nativeEnum(StudySubPlanStatus),
  maxStudy: z.number(),
  suggestions: z.array(SuggestionSchema),
  reports: z.array(SubPlanReportSchema),
  data: z.object({
    title: z.string().optional(),
    approach: z.enum(["adjacent", "sub-segments", "mix of both"]).optional(),
    audience: z.string().optional(),
    justification: z.string().optional(),
    considerations: z.array(z.string()).optional(),
  }),
});

export const StudyPlanSchema = z.object({
  id: z.string(),
  name: z.string(),
  projectId: z.string(),
  audience: z.string(),
  researchGoal: z.string(),
  createdAt: z.date(),
  subplans: z.array(StudySubPlanSchema),
  graphData: z.object({}),
});

export const PaginatedStudyPlansSchema = createPaginatedResultsSchema(StudyPlanSchema);
