import { createPaginatedResultsSchema } from "@/store/utils/create-paginated-results-schema";
import { z } from "zod";

export const SyntheticUserSchema = z.object({
  id: z.string(),
  audienceId: z.string(),
  audienceDescription: z.string(),
  projectId: z.string(),
  createdAt: z.union([z.string(), z.date()]),
  status: z.string(),
  backstory: z.string(),
  description: z.string(),
  personalInformation: z.record(z.string()),
  personalityTraits: z.record(z.string()),
  miscellaneous: z.record(z.string()),
});

export const PaginatedSyntheticUsersSchema = createPaginatedResultsSchema(SyntheticUserSchema);
