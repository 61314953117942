import { Suggestion } from "store/modules/study-plans/types";

export const sortSuggestions = (suggestions: Suggestion[]) => {
  return suggestions.sort((a, b) => {
    // First sort by priority
    if (a.data.priority !== b.data.priority) {
      const priorityOrder = { high: 0, medium: 1, low: 2 };

      return priorityOrder[a.data.priority ?? "low"] - priorityOrder[b.data.priority ?? "low"];
    }

    // Then sort by type, putting "sub-segment" first
    if (a.data.type !== b.data.type) {
      return a.data.type === "sub-segment" ? -1 : 1;
    }

    return 0;
  });
};
