// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { PaginatedSummaries, Summary, SummaryMessage } from "./types";
// Schemas
import { PaginatedSummariesSchema, SummaryMessageSchema, SummarySchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Utils
import { sdkApis } from "@/store/api-sdk";

// RTK Query API slice
export const summaryApi = createApi({
  reducerPath: "summaryApi",
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    /***** --- Get Summaries Query --- *****/
    getSummaries: builder.query<PaginatedSummaries, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.summaries.listSummaries(undefined, undefined, studyId),
      }),
      extraOptions: {
        dataSchema: PaginatedSummariesSchema,
      },
    }),
    /***** --- Get Summary Query --- *****/
    getSummary: builder.query<Summary, { summaryId: string }>({
      query: ({ summaryId }) => ({
        type: "sdk",
        method: sdkApis.summaries.getSummary(summaryId),
      }),
      extraOptions: {
        dataSchema: SummarySchema,
      },
    }),
    /***** --- Generate Summary Mutation --- *****/
    generateSummary: builder.mutation<
      Summary,
      {
        studyId: string;
        summarizationFocus?: string;
      }
    >({
      query: ({ studyId, summarizationFocus }) => ({
        type: "sdk",
        method: sdkApis.studies.generateSummary(studyId, {
          summarizationFocus,
        }),
      }),
      extraOptions: {
        dataSchema: SummarySchema,
      },
    }),
    /***** --- Export Summary Mutation --- *****/
    exportSummary: builder.mutation<
      unknown,
      { summaryId: string; studyDescription: string; fileExtension: string }
    >({
      query: ({ summaryId, fileExtension }) => ({
        type: "sdk",
        method: sdkApis.summaries.exportSummary(summaryId, fileExtension),
        skipParsing: true,
        isFileDownload: true,
      }),
      async onQueryStarted({ studyDescription, fileExtension }, { queryFulfilled }) {
        const response = await queryFulfilled;

        if (response.data) {
          // Handle JSON data differently from other file types
          const blobData: string | Blob =
            fileExtension === "json"
              ? JSON.stringify(response.data, null, 2) // Pretty print JSON with 2 spaces
              : (response.data as Blob);

          const blob = new Blob([blobData], {
            type: fileExtension === "json" ? "application/json" : "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${studyDescription}.${fileExtension}`;
          a.click();
          // Remove the link after the download starts
          setTimeout(() => {
            a.remove();
            URL.revokeObjectURL(url);
          }, 100);
        }
      },
    }),
    askSummaryFollowUp: builder.mutation<SummaryMessage, { summaryId: string; message: string }>({
      query: ({ summaryId, message }) => ({
        type: "sdk",
        method: sdkApis.summaries.summaryFollowUp(summaryId, {
          message,
        }),
        extraOptions: {
          dataSchema: SummaryMessageSchema,
        },
      }),
    }),
  }),
});

// Create the regular slice
const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    /***** --- Reset Summary --- *****/
    resetSummary: () => initialState,
    /***** --- Update Summaries SSE --- *****/
    updateSummariesSse: (state, action: PayloadAction<{ summary: Summary; timestamp: Date }>) => {
      const { summary, timestamp } = action.payload;
      state.lastSseEventTimestamp = timestamp;
      state.data = state.data?.map((s) => (s.id === summary.id ? summary : s));
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          summaryApi.endpoints.getSummaries.matchPending,
          summaryApi.endpoints.getSummary.matchPending,
          summaryApi.endpoints.generateSummary.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          summaryApi.endpoints.getSummaries.matchRejected,
          summaryApi.endpoints.getSummaries.matchFulfilled,
          summaryApi.endpoints.getSummary.matchRejected,
          summaryApi.endpoints.getSummary.matchFulfilled,
          summaryApi.endpoints.generateSummary.matchRejected,
          summaryApi.endpoints.generateSummary.matchFulfilled
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get Summaries Fulfilled --- *****/
      .addMatcher(summaryApi.endpoints.getSummaries.matchFulfilled, (state, action) => {
        state.data = action.payload.items;
      })
      /***** --- Handle Get Summary Fulfilled --- *****/
      .addMatcher(summaryApi.endpoints.getSummary.matchFulfilled, (state, action) => {
        const summaryExists = state.data?.find((summary) => summary.id === action.payload.id);
        if (summaryExists) {
          state.data = state.data?.map((summary) =>
            summary.id === action.payload.id ? action.payload : summary
          );
        } else {
          state.data = [...(state.data || []), action.payload];
        }
      })
      /***** --- Handle Generate Summary Fulfilled --- *****/
      .addMatcher(summaryApi.endpoints.generateSummary.matchFulfilled, (state, action) => {
        state.data = [action.payload, ...(state.data || [])];
      })
      /***** --- Handle Error --- *****/
      .addMatcher(
        isAnyOf(
          summaryApi.endpoints.getSummaries.matchRejected,
          summaryApi.endpoints.getSummary.matchRejected,
          summaryApi.endpoints.generateSummary.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const { resetSummary, updateSummariesSse } = summarySlice.actions;

// Export hooks
export const {
  useGetSummariesQuery,
  useLazyGetSummariesQuery,
  useGetSummaryQuery,
  useLazyGetSummaryQuery,
  useGenerateSummaryMutation,
  useExportSummaryMutation,
  useAskSummaryFollowUpMutation,
} = summaryApi;

// Combine the reducers
export const summaryReducer = {
  [summaryApi.reducerPath]: summaryApi.reducer,
  summary: summarySlice.reducer,
};
