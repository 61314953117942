export const hasType = (action: unknown): action is { type: string } => {
  return (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    action.type !== null &&
    typeof action.type === "string"
  );
};

export const hasPayloadResourceId = (
  action: unknown
): action is { payload: { resourceId: string } } => {
  return (
    typeof action === "object" &&
    action !== null &&
    "payload" in action &&
    action.payload !== null &&
    typeof action.payload === "object" &&
    "resourceId" in action.payload &&
    typeof action.payload.resourceId === "string"
  );
};
