import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { z } from "zod";
import {
  PaginatedStudyPlansSchema,
  PaginatedSuggestionsSchema,
  StudyPlanSchema,
  StudySubPlanSchema,
  SubPlanReportSchema,
  SuggestionSchema,
} from "./schemas";

export type StudyPlan = z.infer<typeof StudyPlanSchema>;
export type PaginatedStudyPlans = z.infer<typeof PaginatedStudyPlansSchema>;
export type StudySubPlan = z.infer<typeof StudySubPlanSchema>;
export type Suggestion = z.infer<typeof SuggestionSchema>;
export type SubPlanReport = z.infer<typeof SubPlanReportSchema>;
export type PaginatedSuggestions = z.infer<typeof PaginatedSuggestionsSchema>;

export enum StudySubPlanStatus {
  Generating = "generating",
  Ready = "ready",
}

export enum SuggestionStatus {
  Created = "created",
  Executing = "executing",
  Done = "done",
}

export enum SubPlanReportStatus {
  Created = "created",
  Generating = "generating",
  Done = "done",
}

export type StateProps = {
  loading: boolean;
  error?: TransportFailure;
  data: {
    studyPlan?: StudyPlan;
    studyPlansList?: StudyPlan[];
  };
  lastSseEventTimestamp?: Date;
};
