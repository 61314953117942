// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import {
  PaginatedStudyPlans,
  PaginatedSuggestions,
  StudyPlan,
  StudySubPlan,
  Suggestion,
  SuggestionStatus,
  SubPlanReport,
} from "./types";
// Schemas
import {
  PaginatedStudyPlansSchema,
  PaginatedSuggestionsSchema,
  StudyPlanSchema,
  StudySubPlanSchema,
  SuggestionSchema,
} from "./schemas";
// Initial state
import { initialState } from "./initial-state";
import { sdkApis } from "@/store/api-sdk";
import { Study } from "store/modules/studies/types";
import { sortSuggestions } from "./utils/sort-suggestions";

// Create the API slice
export const studyPlansApi = createApi({
  reducerPath: "studyPlansApi",
  baseQuery: customBaseQuery,
  tagTypes: ["StudyPlans"],
  endpoints: (builder) => ({
    getPlansList: builder.query<
      PaginatedStudyPlans,
      { projectId: string; page?: number; pageSize?: number }
    >({
      query: ({ projectId, page, pageSize }) => ({
        type: "sdk",
        method: sdkApis.plans.listPlans(undefined, projectId, page, pageSize),
      }),
      extraOptions: {
        dataSchema: PaginatedStudyPlansSchema,
      },
    }),
    getPlanById: builder.query<StudyPlan, { planId: string }>({
      query: ({ planId }) => ({
        type: "sdk",
        method: sdkApis.plans.getPlan(planId),
      }),
      transformResponse: (response: StudyPlan) => {
        return {
          ...response,
          subplans: response.subplans.map((subPlan) => ({
            ...subPlan,
            suggestions: sortSuggestions(subPlan.suggestions),
          })),
        };
      },
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    createPlan: builder.mutation<
      StudyPlan,
      { projectId: string; audience: string; researchGoal: string }
    >({
      query: ({ projectId, audience, researchGoal }) => ({
        type: "sdk",
        method: sdkApis.plans.createPlan({
          projectId,
          audience,
          researchGoal,
        }),
      }),
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    editPlan: builder.mutation<
      StudyPlan,
      {
        planId: string;
        name: string;
        audience: string;
        researchGoal: string;
        graphData: Record<string, unknown>;
      }
    >({
      query: ({ planId, name, audience, researchGoal, graphData }) => ({
        type: "sdk",
        method: sdkApis.plans.updatePlan(planId, {
          name,
          audience,
          researchGoal,
          graphData,
        }),
      }),
      extraOptions: {
        dataSchema: StudyPlanSchema,
      },
    }),
    createSubPlan: builder.mutation<
      StudySubPlan,
      { planId: string; parentId: string | null; maxStudy: number }
    >({
      query: ({ planId, parentId, maxStudy }) => ({
        type: "sdk",
        method: sdkApis.subPlans.createSubplan({
          planId,
          parentId,
          maxStudy,
        }),
      }),
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    getSubPlanById: builder.query<StudySubPlan, { subPlanId: string }>({
      query: ({ subPlanId }) => ({
        type: "sdk",
        method: sdkApis.subPlans.getSubplan(subPlanId),
      }),
      transformResponse: (response: StudySubPlan) => {
        return {
          ...response,
          suggestions: sortSuggestions(response.suggestions),
        };
      },
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    generateSubPlanReport: builder.mutation<
      StudySubPlan,
      { subPlanId: string; suggestionIds: string[] }
    >({
      query: ({ subPlanId, suggestionIds }) => ({
        type: "sdk",
        method: sdkApis.subPlans.generateSubplanReport(subPlanId, { suggestions: suggestionIds }),
      }),
      transformResponse: (response: StudySubPlan) => {
        return {
          ...response,
          suggestions: sortSuggestions(response.suggestions),
        };
      },
      extraOptions: {
        dataSchema: StudySubPlanSchema,
      },
    }),
    executeSuggestedStudy: builder.mutation<Suggestion, { suggestionId: string }>({
      query: ({ suggestionId }) => ({
        type: "sdk",
        method: sdkApis.suggestions.executeSuggestion(suggestionId),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
    getSuggestedStudies: builder.query<PaginatedSuggestions, { suggestionIds: string[] }>({
      query: ({ suggestionIds }) => ({
        type: "sdk",
        method: sdkApis.suggestions.listSuggestions(suggestionIds.join(",")),
      }),
      extraOptions: {
        dataSchema: PaginatedSuggestionsSchema,
      },
    }),
    deleteSuggestedStudy: builder.mutation<void, { suggestionId: string }>({
      query: ({ suggestionId }) => ({
        type: "sdk",
        method: sdkApis.suggestions.deleteSuggestion(suggestionId),
      }),
    }),
    extendStudyPlan: builder.mutation<Suggestion, { subPlanId: string }>({
      query: ({ subPlanId }) => ({
        type: "sdk",
        method: sdkApis.subPlans.extendSubplan(subPlanId),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
    regenerateSuggestion: builder.mutation<Suggestion, { suggestionId: string }>({
      query: ({ suggestionId }) => ({
        type: "sdk",
        method: sdkApis.suggestions.regenerateSuggestion(suggestionId),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
    updateSuggestedStudy: builder.mutation<
      Suggestion,
      { suggestionId: string; quantity: number; data: Suggestion["data"] }
    >({
      query: ({ suggestionId, quantity, data }) => ({
        type: "sdk",
        method: sdkApis.suggestions.updateSuggestion(suggestionId, {
          quantity,
          data,
        }),
      }),
      extraOptions: {
        dataSchema: SuggestionSchema,
      },
    }),
    regenerateSuggestedStudy: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.regenerateAllInterviews(studyId),
      }),
    }),
    getReportPdf: builder.query<Blob, { subPlanId: string; reportId: string }>({
      query: ({ subPlanId, reportId }) => ({
        type: "sdk",
        method: sdkApis.subPlans.getReportPdf(subPlanId, reportId),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        const { data: blob } = await queryFulfilled;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.pdf";
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      },
    }),
  }),
});

// Create the regular slice
export const studyPlansSlice = createSlice({
  name: "studyPlans",
  initialState,
  reducers: {
    /***** --- Reset Study Plans --- *****/
    resetStudyPlan: (state) => {
      state.data.studyPlan = initialState.data.studyPlan;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
    updateStudyPlanSse: (
      state,
      action: PayloadAction<{ studyPlan: StudyPlan; timestamp: Date }>
    ) => {
      const { studyPlan, timestamp } = action.payload;
      state.lastSseEventTimestamp = timestamp;
      state.data.studyPlan = studyPlan;
    },
    updateStudySubPlanSse: (
      state,
      action: PayloadAction<{ studySubPlan: StudySubPlan; timestamp: Date }>
    ) => {
      const { studySubPlan, timestamp } = action.payload;
      const studySubPlanExists = state.data.studyPlan?.subplans.find(
        (subPlan) => subPlan.id === studySubPlan.id
      );

      state.lastSseEventTimestamp = timestamp;
      if (state.data.studyPlan) {
        state.data.studyPlan = {
          ...state.data.studyPlan,
          subplans: studySubPlanExists
            ? (state.data.studyPlan?.subplans || []).map((subPlan) =>
                subPlan.id === studySubPlan.id ? studySubPlan : subPlan
              )
            : [...(state.data.studyPlan?.subplans || []), studySubPlan],
        };
      }
    },
    updateStudySubPlanSuggestionSse: (
      state,
      action: PayloadAction<{ suggestion: Suggestion; timestamp: Date }>
    ) => {
      const { suggestion, timestamp } = action.payload;
      const suggestionExists = state.data.studyPlan?.subplans.find((subPlan) =>
        subPlan.suggestions.find((s) => s.id === suggestion.id)
      );

      state.lastSseEventTimestamp = timestamp;
      if (state.data.studyPlan) {
        state.data.studyPlan = {
          ...state.data.studyPlan,
          subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
            ...subPlan,
            suggestions: suggestionExists
              ? subPlan.suggestions.map((s) => (s.id === suggestion.id ? suggestion : s))
              : [...subPlan.suggestions, suggestion],
          })),
        };
      }
    },
    updateStudySubPlanReportSse: (
      state,
      action: PayloadAction<{ subPlanReport: SubPlanReport; timestamp: Date }>
    ) => {
      const { subPlanReport, timestamp } = action.payload;
      const reportSubPlan = state.data.studyPlan?.subplans.find(
        (subPlan) => subPlan.id === subPlanReport.subplanId
      );
      const reportExists = reportSubPlan?.reports.find((report) => report.id === subPlanReport.id);

      state.lastSseEventTimestamp = timestamp;
      if (state.data.studyPlan) {
        state.data.studyPlan = {
          ...state.data.studyPlan,
          subplans: state.data.studyPlan?.subplans.map((subPlan) =>
            subPlan.id === subPlanReport.subplanId
              ? {
                  ...subPlan,
                  reports: reportExists
                    ? subPlan.reports.map((report) =>
                        report.id === subPlanReport.id ? subPlanReport : report
                      )
                    : [...subPlan.reports, subPlanReport],
                }
              : subPlan
          ),
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchPending,
          studyPlansApi.endpoints.getPlanById.matchPending,
          studyPlansApi.endpoints.createPlan.matchPending,
          studyPlansApi.endpoints.createSubPlan.matchPending,
          studyPlansApi.endpoints.getSubPlanById.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchFulfilled,
          studyPlansApi.endpoints.getPlansList.matchRejected,
          studyPlansApi.endpoints.getPlanById.matchFulfilled,
          studyPlansApi.endpoints.getPlanById.matchRejected,
          studyPlansApi.endpoints.createPlan.matchFulfilled,
          studyPlansApi.endpoints.createPlan.matchRejected,
          studyPlansApi.endpoints.createSubPlan.matchFulfilled,
          studyPlansApi.endpoints.createSubPlan.matchRejected,
          studyPlansApi.endpoints.getSubPlanById.matchFulfilled,
          studyPlansApi.endpoints.getSubPlanById.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(studyPlansApi.endpoints.getPlansList.matchFulfilled, (state, action) => {
        state.data.studyPlansList = action.payload.items;
      })
      .addMatcher(studyPlansApi.endpoints.editPlan.matchFulfilled, (state, action) => {
        state.data.studyPlansList = state.data.studyPlansList?.map((plan) =>
          plan.id === action.payload.id ? action.payload : plan
        );
      })
      .addMatcher(studyPlansApi.endpoints.getPlanById.matchFulfilled, (state, action) => {
        state.data.studyPlan = action.payload;
      })
      .addMatcher(studyPlansApi.endpoints.createPlan.matchFulfilled, (state, action) => {
        state.data.studyPlan = action.payload;
        state.data.studyPlansList?.unshift(action.payload);
      })
      .addMatcher(studyPlansApi.endpoints.createSubPlan.matchFulfilled, (state, action) => {
        state.data.studyPlan?.subplans.push(action.payload);
      })
      .addMatcher(studyPlansApi.endpoints.generateSubPlanReport.matchFulfilled, (state, action) => {
        const updatedSubPlan = action.payload;
        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) =>
              subPlan.id === updatedSubPlan.id ? updatedSubPlan : subPlan
            ),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.getSubPlanById.matchFulfilled, (state, action) => {
        const subPlanResponse = action.payload;
        const subPlanExists = state.data.studyPlan?.subplans.find(
          (subPlan) => subPlan.id === subPlanResponse.id
        );

        if (subPlanExists && state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) =>
              subPlan.id === subPlanResponse.id ? subPlanResponse : subPlan
            ),
          };
        } else {
          state.data.studyPlan?.subplans.push(action.payload);
        }
      })
      .addMatcher(studyPlansApi.endpoints.getSuggestedStudies.matchFulfilled, (state, action) => {
        const suggestionsResponse = action.payload;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) => {
                const updatedSuggestion = suggestionsResponse.items.find(
                  (responseSuggestion) => responseSuggestion.id === suggestion.id
                );
                return updatedSuggestion ? updatedSuggestion : suggestion;
              }),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.executeSuggestedStudy.matchFulfilled, (state, action) => {
        const suggestionResponse = action.payload;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) =>
                suggestion.id === suggestionResponse.id ? suggestionResponse : suggestion
              ),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.regenerateSuggestion.matchFulfilled, (state, action) => {
        const updatedSuggestion = {
          ...action.payload,
          data: {},
        };

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.map((suggestion) =>
                suggestion.id === updatedSuggestion.id ? updatedSuggestion : suggestion
              ),
            })),
          };
        }
      })
      .addMatcher(studyPlansApi.endpoints.deleteSuggestedStudy.matchFulfilled, (state, action) => {
        const suggestionId = action.meta.arg.originalArgs.suggestionId;

        if (state.data.studyPlan) {
          state.data.studyPlan = {
            ...state.data.studyPlan,
            subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
              ...subPlan,
              suggestions: subPlan.suggestions.filter(
                (suggestion) => suggestion.id !== suggestionId
              ),
            })),
          };
        }
      })
      .addMatcher(
        studyPlansApi.endpoints.regenerateSuggestedStudy.matchFulfilled,
        (state, action) => {
          const studyResponse = action.payload;
          if (state.data.studyPlan) {
            state.data.studyPlan = {
              ...state.data.studyPlan,
              subplans: state.data.studyPlan?.subplans.map((subPlan) => ({
                ...subPlan,
                suggestions: subPlan.suggestions.map((suggestion) => ({
                  ...suggestion,
                  status:
                    suggestion.studyId === studyResponse.id
                      ? SuggestionStatus.Executing
                      : suggestion.status,
                })),
              })),
            };
          }
        }
      )
      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          studyPlansApi.endpoints.getPlansList.matchRejected,
          studyPlansApi.endpoints.getPlanById.matchRejected,
          studyPlansApi.endpoints.createPlan.matchRejected,
          studyPlansApi.endpoints.editPlan.matchRejected,
          studyPlansApi.endpoints.createSubPlan.matchRejected,
          studyPlansApi.endpoints.getSubPlanById.matchRejected,
          studyPlansApi.endpoints.regenerateSuggestedStudy.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export actions
export const {
  resetStudyPlan,
  updateStudyPlanSse,
  updateStudySubPlanSse,
  updateStudySubPlanSuggestionSse,
  updateStudySubPlanReportSse,
} = studyPlansSlice.actions;

// export hooks
export const {
  useGetPlansListQuery,
  useGetPlanByIdQuery,
  useCreatePlanMutation,
  useEditPlanMutation,
  useCreateSubPlanMutation,
  useGetSubPlanByIdQuery,
  useLazyGetSubPlanByIdQuery,
  useExecuteSuggestedStudyMutation,
  useGetSuggestedStudiesQuery,
  useDeleteSuggestedStudyMutation,
  useUpdateSuggestedStudyMutation,
  useGenerateSubPlanReportMutation,
  useRegenerateSuggestedStudyMutation,
  useLazyGetReportPdfQuery,
  useExtendStudyPlanMutation,
  useRegenerateSuggestionMutation,
} = studyPlansApi;

// Combine the reducers
export const studyPlansReducer = {
  [studyPlansApi.reducerPath]: studyPlansApi.reducer,
  studyPlans: studyPlansSlice.reducer,
};
