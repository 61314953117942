import { StateProps } from "./types";

export const initialState: StateProps = {
  loading: false,
  error: undefined,
  data: {
    currentUser: undefined,
    users: undefined,
  },
};
