// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
import { parseError } from "store/utils/parse-error";
// Types
import { PaginatedStudies, Study } from "./types";
// Schemas
import { PaginatedStudiesSchema, StudySchema } from "./schemas";
// Initial state
import { initialState } from "./initial-state";
// Store modules
import { deleteStudyHistory, updateStudyHistory } from "store/modules/history/slice";
import { sdkApis } from "@/store/api-sdk";

// Create the API slice
export const studiesApi = createApi({
  reducerPath: "studyApi",
  baseQuery: customBaseQuery,
  tagTypes: ["Study"],
  endpoints: (builder) => ({
    /***** --- Get Studies Query --- *****/
    getStudies: builder.query<
      PaginatedStudies,
      { projectId: string; page?: number; pageSize?: number }
    >({
      query: ({ projectId, page, pageSize }) => ({
        type: "sdk",
        method: sdkApis.studies.listStudies(
          undefined,
          projectId,
          undefined,
          undefined,
          page,
          pageSize
        ),
      }),
      extraOptions: {
        dataSchema: PaginatedStudiesSchema,
      },
    }),
    /***** --- Get Study Query --- *****/
    getStudy: builder.query<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.getStudy(studyId),
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Create Study Mutation --- *****/
    createStudy: builder.mutation<
      Study,
      {
        projectId: string;
        language: string;
        audiences: string[];
        problems?: string[];
        files?: string[];
        solutionId?: string;
        researchGoalId?: string;
        strategy: string;
      }
    >({
      query: ({
        projectId,
        language,
        audiences,
        problems,
        files,
        solutionId,
        researchGoalId,
        strategy,
      }) => ({
        type: "sdk",
        method: sdkApis.studies.createStudy({
          projectId,
          language,
          audiences,
          problems: problems ?? [],
          files: files ?? [],
          solutionId,
          researchGoalId,
          strategy,
        }),
      }),
    }),
    /***** --- Generate Study Interviews Mutation --- *****/
    generateStudyInterviews: builder.mutation<
      Study,
      { studyId: string; syntheticUsers: string[]; customScript?: string }
    >({
      query: ({ studyId, syntheticUsers, customScript }) => ({
        type: "sdk",
        method: sdkApis.studies.interview(studyId, {
          syntheticUsers,
          customScript,
        }),
      }),
    }),
    /***** --- Regenerate Study Interviews Mutation --- *****/
    regenerateStudyInterviews: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.regenerateAllInterviews(studyId),
      }),
    }),
    /***** --- Update Study Description Mutation --- *****/
    updateStudyDescription: builder.mutation<Study, { studyId: string; description: string }>({
      query: ({ studyId, description }) => ({
        type: "sdk",
        method: sdkApis.studies.editStudy(studyId, {
          description,
        }),
      }),
      async onQueryStarted({ studyId, description }, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(updateStudyHistory({ id: studyId, description }));
      },
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Delete Study Mutation --- *****/
    deleteStudy: builder.mutation<void, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.deleteStudy(studyId),
      }),
      async onQueryStarted({ studyId }, { queryFulfilled, dispatch }) {
        await queryFulfilled;
        dispatch(deleteStudyHistory({ id: studyId }));
      },
    }),
    /***** --- Bookmark Study Mutation --- *****/
    bookmarkStudy: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.editStudy(studyId, {
          bookmarked: true,
        }),
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
    /***** --- Unbookmark Study Mutation --- *****/
    unbookmarkStudy: builder.mutation<Study, { studyId: string }>({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.editStudy(studyId, {
          bookmarked: false,
        }),
      }),
      extraOptions: {
        dataSchema: StudySchema,
      },
    }),
  }),
});

// Create the regular slice
export const studiesSlice = createSlice({
  name: "study",
  initialState,
  reducers: {
    /***** --- Reset Study --- *****/
    resetCurrentStudy: (state) => {
      state.data = {
        ...state.data,
        currentStudy: undefined,
      };
    },
    updateStudiesSse: (state, action: PayloadAction<{ study: Study; timestamp: Date }>) => {
      const { study, timestamp } = action.payload;
      const isCurrentStudy = state.data?.currentStudy?.id === study.id;
      const studyExists = !!state.data?.studies?.find((s) => s.id === study.id);

      state.lastSseEventTimestamp = timestamp;
      state.data = {
        ...state.data,
        currentStudy: isCurrentStudy ? study : state.data?.currentStudy,
        studies: studyExists
          ? state.data?.studies?.map((s) => (s.id === study.id ? study : s))
          : [...(state.data?.studies || []), study].sort(
              (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          studiesApi.endpoints.getStudy.matchPending,
          studiesApi.endpoints.getStudies.matchPending,
          studiesApi.endpoints.createStudy.matchPending,
          studiesApi.endpoints.updateStudyDescription.matchPending,
          studiesApi.endpoints.deleteStudy.matchPending,
          studiesApi.endpoints.generateStudyInterviews.matchPending,
          studiesApi.endpoints.regenerateStudyInterviews.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          studiesApi.endpoints.getStudy.matchFulfilled,
          studiesApi.endpoints.getStudy.matchRejected,
          studiesApi.endpoints.getStudies.matchFulfilled,
          studiesApi.endpoints.getStudies.matchRejected,
          studiesApi.endpoints.createStudy.matchFulfilled,
          studiesApi.endpoints.createStudy.matchRejected,
          studiesApi.endpoints.updateStudyDescription.matchFulfilled,
          studiesApi.endpoints.updateStudyDescription.matchRejected,
          studiesApi.endpoints.deleteStudy.matchFulfilled,
          studiesApi.endpoints.deleteStudy.matchRejected,
          studiesApi.endpoints.generateStudyInterviews.matchFulfilled,
          studiesApi.endpoints.generateStudyInterviews.matchRejected,
          studiesApi.endpoints.regenerateStudyInterviews.matchFulfilled,
          studiesApi.endpoints.regenerateStudyInterviews.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get Studies Query --- *****/
      .addMatcher(isAnyOf(studiesApi.endpoints.getStudies.matchFulfilled), (state, action) => {
        state.data = {
          ...state.data,
          studies: action.payload.items,
        };
      })
      /***** --- Handle Fulfilled --- *****/
      .addMatcher(
        isAnyOf(
          studiesApi.endpoints.getStudy.matchFulfilled,
          studiesApi.endpoints.createStudy.matchFulfilled,
          studiesApi.endpoints.bookmarkStudy.matchFulfilled,
          studiesApi.endpoints.unbookmarkStudy.matchFulfilled,
          studiesApi.endpoints.generateStudyInterviews.matchFulfilled,
          studiesApi.endpoints.regenerateStudyInterviews.matchFulfilled
        ),
        (state, action) => {
          state.data = {
            ...state.data,
            currentStudy: action.payload,
          };
        }
      )

      /***** --- Handle Errors --- *****/
      .addMatcher(
        isAnyOf(
          studiesApi.endpoints.getStudy.matchRejected,
          studiesApi.endpoints.getStudies.matchRejected,
          studiesApi.endpoints.createStudy.matchRejected,
          studiesApi.endpoints.updateStudyDescription.matchRejected,
          studiesApi.endpoints.deleteStudy.matchRejected,
          studiesApi.endpoints.bookmarkStudy.matchRejected,
          studiesApi.endpoints.unbookmarkStudy.matchRejected,
          studiesApi.endpoints.generateStudyInterviews.matchRejected,
          studiesApi.endpoints.regenerateStudyInterviews.matchRejected
        ),
        (state, action) => {
          const error = parseError(action.error);
          state.error = error;
        }
      );
  },
});

// Export actions
export const { resetCurrentStudy, updateStudiesSse } = studiesSlice.actions;

// Export hooks
export const {
  useGetStudyQuery,
  useLazyGetStudyQuery,
  useGetStudiesQuery,
  useCreateStudyMutation,
  useUpdateStudyDescriptionMutation,
  useDeleteStudyMutation,
  useBookmarkStudyMutation,
  useUnbookmarkStudyMutation,
  useGenerateStudyInterviewsMutation,
  useRegenerateStudyInterviewsMutation,
} = studiesApi;

// Combine the reducers
export const studiesReducer = {
  [studiesApi.reducerPath]: studiesApi.reducer,
  studies: studiesSlice.reducer,
};
