// Redux Toolkit
import { createApi } from "@reduxjs/toolkit/query/react";
// Store utils
import { customBaseQuery } from "store/utils/custom-base-query";
// Types
import { KnowledgeGraph } from "./types";
// Schemas
import { KnowledgeGraphSchema } from "./schemas";
import { sdkApis } from "@/store/api-sdk";
import { parseError } from "@/store/utils/parse-error";
import { isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
// Create the API slice
export const knowledgeGraphApi = createApi({
  reducerPath: "knowledgeGraphApi",
  baseQuery: customBaseQuery,
  tagTypes: ["KnowledgeGraph"],
  endpoints: (builder) => ({
    /***** --- Generate Knowledge Graph Mutation --- *****/
    generateKnowledgeGraph: builder.mutation<
      KnowledgeGraph,
      {
        studyId: string;
      }
    >({
      query: ({ studyId }) => ({
        type: "sdk",
        method: sdkApis.studies.generateKnowledgeGraph(studyId),
      }),
      extraOptions: {
        dataSchema: KnowledgeGraphSchema,
      },
    }),
    /***** --- Get Knowledge Graph Query --- *****/
    getKnowledgeGraph: builder.query<KnowledgeGraph, { knowledgeGraphId: string }>({
      query: ({ knowledgeGraphId }) => ({
        type: "sdk",
        method: sdkApis.knowledgeGraph.getKnowledgeGraph(knowledgeGraphId),
      }),
      providesTags: ["KnowledgeGraph"],
      extraOptions: {
        dataSchema: KnowledgeGraphSchema,
        skipAuthentication: true,
      },
    }),
  }),
});

export const knowledgeGraphSlice = createSlice({
  name: "knowledgeGraph",
  initialState,
  reducers: {
    /***** --- Reset Knowledge Graph --- *****/
    resetKnowledgeGraph: () => initialState,
    /***** --- Update Knowledge Graph SSE --- *****/
    updateKnowledgeGraphSse: (
      state,
      action: PayloadAction<{ knowledgeGraph: KnowledgeGraph; timestamp: Date }>
    ) => {
      const { knowledgeGraph, timestamp } = action.payload;
      state.lastSseEventTimestamp = timestamp;
      state.data.knowledgeGraph = knowledgeGraph;
    },
  },
  extraReducers: (builder) => {
    builder
      /***** --- Handle Loading --- *****/
      .addMatcher(
        isAnyOf(
          knowledgeGraphApi.endpoints.generateKnowledgeGraph.matchPending,
          knowledgeGraphApi.endpoints.getKnowledgeGraph.matchPending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          knowledgeGraphApi.endpoints.generateKnowledgeGraph.matchFulfilled,
          knowledgeGraphApi.endpoints.generateKnowledgeGraph.matchRejected,
          knowledgeGraphApi.endpoints.getKnowledgeGraph.matchFulfilled,
          knowledgeGraphApi.endpoints.getKnowledgeGraph.matchRejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      /***** --- Handle Get Knowledge Graph Optimistic Update --- *****/
      .addMatcher(knowledgeGraphApi.endpoints.getKnowledgeGraph.matchFulfilled, (state, action) => {
        state.data.knowledgeGraph = action.payload;
      })
      .addMatcher(
        knowledgeGraphApi.endpoints.generateKnowledgeGraph.matchFulfilled,
        (state, action) => {
          state.data.knowledgeGraph = action.payload;
        }
      )
      .addMatcher(
        isAnyOf(
          knowledgeGraphApi.endpoints.generateKnowledgeGraph.matchRejected,
          knowledgeGraphApi.endpoints.getKnowledgeGraph.matchRejected
        ),
        (state, action) => {
          state.error = parseError(action.error);
        }
      );
  },
});

// Export hooks
export const {
  useGenerateKnowledgeGraphMutation,
  useGetKnowledgeGraphQuery,
  useLazyGetKnowledgeGraphQuery,
} = knowledgeGraphApi;

export const { resetKnowledgeGraph, updateKnowledgeGraphSse } = knowledgeGraphSlice.actions;

// Combine the reducers
export const knowledgeGraphReducer = {
  [knowledgeGraphApi.reducerPath]: knowledgeGraphApi.reducer,
  knowledgeGraph: knowledgeGraphSlice.reducer,
};
